import React, { Component } from 'react';
import Head from 'next/head';
import Login from '@studionand/depi/src/components/depi/Login';
import DepiLayout from '@studionand/depi/src/components/DepiLayout';

class LoginPage extends Component {
  static async getInitialProps(ctx) {
    return {};
  }

  render() {
    return (
      <DepiLayout>
        <Head>
          <title>DEPI - Anmelden</title>
        </Head>
        <Login />
      </DepiLayout>
    );
  }
}

export default LoginPage;
